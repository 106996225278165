
export const initialState = {
  fetching: false,
  schools: [],
  countries: [],
  cities: [],
  nationalities: [],
  error: {},
};

export default function reducer(
  state = initialState,
  action,
) {
  switch (action.type) {
    case 'SCHOOLS_PENDING':
      return {
        ...state,
        fetching: true,
      }

    case 'SCHOOLS_FULFILLED':
      return {
        ...state,
        ...action.payload.data.data,
        fetching: false,
      }

    case 'COUNTRIES_FULFILLED':
      return {
        ...state,
        countries: action.payload.data.data.countries,
        fetching: false,
      }

    case 'CITIES_FULFILLED':
      return {
        ...state,
        cities: action.payload.data.data.cities,
        fetching: false,
      }

    case 'NATIONALITIES_FULFILLED':
      return {
        ...state,
        nationalities: action.payload.data.data.nationalities,
        fetching: false,
      }

    case 'SCHOOLS_REJECTED':
      return {
        ...state,
        fetching: false,
      }

    default:
      return state;
  }
}
