import React from 'react'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import SelectUI from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';

const Select = ({
  label,
  input,
  options = [],
  valueKey,
  labelKey,
  meta,
}) => {
  return (
    <FormControl
      error={meta.error && meta.touched}
      variant="outlined"
    >
      <InputLabel id={`select-${input.name}`}>{label}</InputLabel>
      <SelectUI
        labelId={`select-${input.name}`}
        value={input.value}
        InputProps={input}
        onChange={input.onChange}
        label={label}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {options.map(data => (
          <MenuItem value={data[valueKey]}>{data[labelKey]}</MenuItem>
        ))}
      </SelectUI>
      {meta.error && meta.touched &&
        <FormHelperText>{meta.error}</FormHelperText>
      }
    </FormControl>
  )
}

export default Select
