import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { useDispatch, useSelector } from 'react-redux';

import { citiesLoad, countriesLoad, nationalitiesLoad, schools, schoolsPost, schoolsUpdate } from './redux/actionCreators';
import SchoolDialog from './SchoolDialog';
import SchoolForm from './SchoolForm';
import { Form } from 'react-final-form';
import Space from 'components/Space';


const Schools = () => {
  const [open, setOpen] = React.useState(false);
  const [schoolDetail, setSchoolDetail] = React.useState({});
  const {
    schools: listSchools,
  } = useSelector(state => state.schools)
  const dispatch = useDispatch();

  const handleClickOpen = (detail) => {
    setSchoolDetail({
      id: detail.id || null,
      school_attributes: {
        ...detail,
        name_en: detail.name,
        // school_type: detail.school_type_value
      },
    })
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (values) => {
    if (!values.id) {
      delete values.id;
      dispatch(schoolsPost({
        ...values,
        school_admin_attributes: {
          email: values.school_attributes?.email,
          mobile: values.school_attributes?.mobile,
          city_id: values.school_attributes?.city_id,
          // nationality_id: values.school_attributes?.nationality_id,
        },
      }))
        .then(() => {
          setOpen(false)
          dispatch(schools())
        });
    } else {
      dispatch(schoolsUpdate({
        id: values.id,
        ...values.school_attributes,
      }))
      .then(() => {
        dispatch(schools())
      })
    }
  }

  React.useEffect(() => {
    dispatch(schools())
    dispatch(countriesLoad())
    dispatch(citiesLoad())
    dispatch(nationalitiesLoad())
  }, [dispatch]);

  return (
    <div className="cs-schools">
      <Grid container justify="flex-end">
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleClickOpen({})}
        >
          Create new School
        </Button>
      </Grid>
      <Space size={20} />
      <TableContainer
        component={Paper}
        style={{
          maxHeight: '80vh',
        }}
      >
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>name</TableCell>
              <TableCell align="right">slug</TableCell>
              <TableCell align="left">School type</TableCell>
              <TableCell align="right">email</TableCell>
              <TableCell align="right">phone</TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listSchools.map((row) => (
              <TableRow key={row.name}>
                <TableCell component="th" scope="row">{row.name}</TableCell>
                <TableCell align="right">{row.slug}</TableCell>
                <TableCell align="left">{row.school_type_text}</TableCell>
                <TableCell align="right">{row.email}</TableCell>
                <TableCell align="right">{row.phone}</TableCell>
                <TableCell align="right">
                  <Button
                    onClick={() => handleClickOpen(row)}
                    color="primary"
                  >
                    Edit
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <SchoolDialog
        open={open}
        onClose={handleClose}
        title={schoolDetail.id ? schoolDetail.school_attributes.name_en : 'Add new School'}
      >
        <Form
          component={SchoolForm}
          onSubmit={handleSubmit}
          initialValues={schoolDetail}
          hideAdminInfo={!!schoolDetail.id}
        />
        </SchoolDialog>
    </div>
  )
}

export default Schools;
