import React from 'react'
import { Switch } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { schools, schoolStatusPut } from './redux/actionCreators';

const SlugStatus = ({ id }) => {
  const dispatch = useDispatch();
  const { schools: listSchools } = useSelector(state => state.schools)
  const school = listSchools.find(f => f.id === id)

  const handleChange = () => {
    dispatch(schoolStatusPut(id, {
      is_enabled: !school.is_enabled
    }))
      .then(() => dispatch(schools()))
  }
  
  return (
    <div>
      <p>School Status (Active/Unactive)</p>
      <Switch
        checked={school.is_enabled}
        color="primary"
        onChange={handleChange}
        name="checkedA"
        inputProps={{ 'aria-label': 'secondary checkbox' }}
      />
    </div>
  )
}

export default SlugStatus