import React from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TranslationForm from './Form';
import { Form } from 'react-final-form';
import Space from 'components/Space';
import { getLocales, updateLocales } from './redux';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'utils/notistack';
import CreateKey from './CreateKey';

const Translation = () => {
  const dispatch = useDispatch();
  const { keys, ar, en } = useSelector(state => state.locales);
  const handleSubmit = (values) => {
    let mAR = ar;
    let mEN = en;

    if (mAR[values.key] !== values.ar) {
      mAR[values.key] = values.ar;
      dispatch(updateLocales({ file_name: 'ar', data: mAR }))
        .then(() => {
          toast.success('updated successfully')
        })
    }
    if (mEN[values.key] !== values.en) {
      mEN[values.key] = values.en;
      dispatch(updateLocales({ file_name: 'en', data: mEN }))
        .then(() => {
          toast.success('updated successfully')
        })
    }
  }

  React.useEffect(() => {
    dispatch(getLocales())
  }, [dispatch])

  return (
    <div style={{ padding: 20 }}>
      <Space size={20} />
      <CreateKey />
      <Space size={20} />
      <TableContainer
        component={Paper}
        style={{
          mainHeight: '80vh',
        }}
      >
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left" style={{ width: 200 }}>Key</TableCell>
              <TableCell align="left">English</TableCell>
              <TableCell align="right">Arabic</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {keys.map((key, i) => (
              <Form
                key={i}
                component={TranslationForm}
                onSubmit={handleSubmit}
                keyLabel={key}
                initialValues={{
                  key,
                  en: en[key],
                  ar: ar[key],
                }}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default Translation
