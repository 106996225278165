import React from 'react'
import { Form, Field } from 'react-final-form'
import { Paper, Typography, Button } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert';
import Space from 'components/Space'
import InputField from 'components/final-form/InputField'
import { useDispatch, useSelector } from 'react-redux'
import { login } from './redux/actionCreators';


const Login = () => {
  const login_store = useSelector(state => state.login)
  const dispatch = useDispatch();

  function onSubmit(values) {
    dispatch(login(values))
      .then(() => window.location.reload())
  }

  return (
    <div className="login-page">
      <Paper>
        <Typography variant="h4" gutterBottom>
          Login
        </Typography>
        <Space size={20} />
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Field
                component={InputField}
                label="User name / Email"
                name="login"
              />
              <Space size={20} />
              <Field
                component={InputField}
                label="Password"
                name="password"
                type="password"
              />
              <Space size={20} />
              <Button
              disabled={login_store.fetching}
                type="submit"
                variant="contained"
                color="primary"
                style={{
                  width: '100%',
                }}
              >
                Submit
              </Button>
            </form>
          )}
        >
        </Form>
        <Space size={20} />
        {login_store.error.error &&
          <Alert severity="error">{login_store.error.error}</Alert>
        }
      </Paper>
    </div>
  )
}

export default Login
