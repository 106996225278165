import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Login from './containers/Login';
import AppBar from 'components/AppBar';
import { useDispatch, useSelector } from 'react-redux';
import { setAuthorization, logout } from 'containers/Login/redux/actionCreators';
import Schools from 'containers/Schools';

import './App.css';
import Translation from 'containers/Translation';

function App() {
  const { isAuthorization } = useSelector(state => state.login)
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(setAuthorization());
  }, [dispatch])

  
  if (!isAuthorization) {
    return (
      <Login />
    );
  }

  return (
    <div className="cs-app">
      <Router>
      <AppBar onLogout={() => dispatch(logout())}/>
        <Switch>
          <Route path="/translations" component={Translation} />
          <Route path="/">
            <Schools />
          </Route>
        </Switch>
      </Router>
    </div>
  )
}

export default App;
