import axios from 'utils/axios'

export function login(values) {
  const payload = new Promise((resolve, reject) => {
    axios.post('/sessions', {
      ...values,
      access_from: 1,
    })
    .then((res) => {
      localStorage.setItem('Authorization', res.data.data.auth_token)
      axios.defaults.headers.common['Authorization'] = res.data.data.auth_token;
      resolve(res)
    })
    .catch(err => reject(err))
  })
  return {
    type: 'LOGIN',
    payload: payload,
  }
}

export function setAuthorization(values) {
  return {
    type: 'SET_AUTHORIZATION',
    payload: localStorage.getItem('Authorization') || null,
  }
}
export function logout() {
  window.localStorage.removeItem('Authorization')
  return {
    type: 'LOGOUT',
  }
}