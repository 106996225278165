
export const initialState = {
  fetching: false,
  user: {},
  transportation: {},
  error: {},
  isAuthorization: false,
};

export default function reducer(
  state = initialState,
  action,
) {
  switch (action.type) {
    case 'SET_AUTHORIZATION':
      return {
        ...state,
        isAuthorization: !!action.payload,
      }
    case 'LOGIN_PENDING':
      return {
        ...state,
        fetching: true,
      }
    case 'LOGIN_FULFILLED':
      return {
        ...state,
        user: action.payload.data.data,
        isAuthorization: true,
        fetching: false,
      }
    case 'LOGIN_REJECTED':
      return {
        ...state,
        error: action.payload.response.data,
        fetching: false,
        isAuthorization: false,
      }
    case 'LOGOUT':
      return {
        ...state,
        fetching: false,
        isAuthorization: false,
      }
    default:
      return state;
  }
}
