
export function getToken() {
  return localStorage.getItem('Authorization');
}

export function setToken(accessToken) {
  localStorage.setItem('Authorization', accessToken);
}

export function removeToken() {
  return localStorage.removeItem('Authorization');
}

export function handleResponseErr(err) {
  // check if there any response from the server
  const response = typeof err.response !== 'undefined' ? err.response : false;
  const hasOwnPropertyState = Object.hasOwnProperty.call(response, 'status');
  const hasOwnPropertyData = Object.hasOwnProperty.call(response, 'data');

  // check if the server send any data
  const resData = hasOwnPropertyData ? response.data : false;

  // Set response code  if user offline
  let errorCode = 0;
  if (hasOwnPropertyState) {
    errorCode = response.status;
  } else if (navigator.onLine) {
    errorCode = 1;
  }

  // if the server not return any response put browser error
  const error = !resData ? err.message : false;

  // the result error message
  let errMesg = null;
  if (error) {
    errMesg = error;
  } else if (resData.message) {
    errMesg = resData.message;
  } else {
    errMesg = resData.error;
  }

  switch (errorCode) {
    case 0:
      return "Can't send request to the server, please check your internet connection!";
    case 1:
      return 'Something went wrong on server!';
    case 400:
      return removeToken();
    case 401:
    case 403:
    // case 500:
      removeToken();
      setTimeout(() => {
        window.location = '/';
      }, 2000);
      return errMesg;

    default:
      return errMesg;
  }
}
