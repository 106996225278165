import React from 'react';
import ReactDOM from 'react-dom';
import { SnackbarProvider, withSnackbar } from 'notistack';

function toast(msg, variant) {
  const mountPoint = document.getElementById('snackbar');
  const Display = withSnackbar(({ message, enqueueSnackbar }) => {
    enqueueSnackbar(message, { variant });
    return null;
  });

  ReactDOM.render(
    <SnackbarProvider maxSnack={3} anchorOrigin={{horizontal: 'right', vertical: 'top'}}>
      <Display message={msg} variant={variant} />
    </SnackbarProvider>,
    mountPoint)
}

function error(msg) {
  toast(msg, 'error')
}

function success(msg) {
  toast(msg, 'success')
}

export default {
  error,
  success,
  toast,
}