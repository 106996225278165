import SchoolDialog from 'containers/Schools/SchoolDialog'
import React from 'react'
import Button from '@material-ui/core/Button';
import { Form } from 'react-final-form';
import Grid from '@material-ui/core/Grid';
import InputField from 'components/final-form/InputField';
import { required } from 'components/final-form/validation';
import { Field } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'utils/notistack';

import Space from 'components/Space';
import { getLocales, updateLocales } from './redux';

const CreateKey = () => {
  const [open, setOpen] = React.useState(false);
  const { keys, ar, en } = useSelector(state => state.locales);
  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    let mAR = ar;
    let mEN = en;
    if(keys.includes(values.key)) {
      return toast.error('key already exists')
    }

    dispatch(updateLocales({
      file_name: 'ar',
      data: {
        ...mAR,
        [values.key]: values.ar,
      },
    }))
    .then(() => {
      toast.success('updated successfully - AR')
      dispatch(getLocales())
      setOpen(false)
    })
    dispatch(updateLocales({
      file_name: 'en',
      data: {
        ...mEN,
        [values.key]: values.en,
      },
    }))
    .then(() => {
      toast.success('updated successfully - EN')
      dispatch(getLocales())
      setOpen(false)
    })
  };

  return (
    <div>
      <Button onClick={() => setOpen(true)} variant="contained" color="primary">
        Create new key
      </Button>
      <SchoolDialog title="Create Key" open={open} onClose={() => setOpen(false)}>
      <Form
        onSubmit={handleSubmit}
        initialValues={{}}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={4}>
              <Grid item xs={4}>
                <Field
                  component={InputField}
                  label="Key name"
                  name="key"
                  validate={required}
                />
              </Grid>            
              <Grid item xs={4}>
                <Field
                  component={InputField}
                  label="English"
                  name="en"
                  validate={required}
                />
              </Grid>            
              <Grid item xs={4}>
                <Field
                  component={InputField}
                  label="Arabic"
                  name="ar"
                  validate={required}
                />
              </Grid>            
            </Grid>
            <Space size={20} />
            <Button variant="contained" color="primary" type="submit">
              Create new key
            </Button>      
          </form>
        )}
      />
        <Space size={20} />
      </SchoolDialog>
    </div>
  )
}

export default CreateKey
