import { combineReducers } from 'redux';
import login from 'containers/Login/redux/reducer';
import schools from 'containers/Schools/redux/reducer';
import { localesReducer } from 'containers/Translation/redux';

const rootReducer = combineReducers({
  login,
  schools,
  locales: localesReducer,
})

export default rootReducer;
