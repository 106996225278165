import { handleAPI } from 'utils/axios'

export function schools() {
  return {
    type: 'SCHOOLS',
    payload: handleAPI('/schools', { rpp: 0 }, 'GET'),
  }
}

export function schoolsPost(values) {
  return {
    type: 'SCHOOLS_POST',
    payload: handleAPI('/schools', {}, 'POST', values, true),
  }
}

export function schoolsUpdate(values) {
  return {
    type: 'SCHOOLS_UPDATE',
    payload: handleAPI(`/schools/${values.id}`, {}, 'PUT', values, true),
  }
}

export function countriesLoad() {
  return {
    type: 'COUNTRIES',
    payload: handleAPI('/countries', { rpp: 0 }),
  }
}

export function citiesLoad() {
  return {
    type: 'CITIES',
    payload: handleAPI('/cities', { rpp: 0 }),
  }
}

export function nationalitiesLoad() {
  return {
    type: 'NATIONALITIES',
    payload: handleAPI('/nationalities', { rpp: 0 }),
  }
}

export function schoolStatusPut(id, data) {
  return {
    type: 'SCHOOL_STATUS_PUT',
    payload: handleAPI(`/schools/${id}/status`, {}, 'PUT', data),
  }
}
