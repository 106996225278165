import React from 'react'
import { Field } from 'react-final-form';
import InputField from 'components/final-form/InputField';
import { required } from 'components/final-form/validation';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const Form = ({
  handleSubmit,
  hideAdminInfo,
  keyLabel,
}) => {
  return (
    <TableRow>
      <TableCell align="left">
        {keyLabel}
      </TableCell>
      <TableCell align="left">
        <Field
          component={InputField}
          label="English"
          name="en"
          validate={required}
          onEnter={handleSubmit}
        />
      </TableCell>
      <TableCell align="right">
        <div dir="rtl">
          <Field
            component={InputField}
            label="Arabic"
            name="ar"
            validate={required}
            onEnter={handleSubmit}
          />
        </div>
      </TableCell>
    </TableRow>
  )
}

export default Form
