import React from 'react'
import TextField from '@material-ui/core/TextField'

const InputField = ({
  label,
  input,
  meta,
  type,
  onEnter,
  maxLength,
}) => {
  return (
    <TextField
      label={label}
      variant="outlined"
      InputProps={input}
      type={type}
      helperText={meta.error && meta.touched && meta.error}
      error={meta.error && meta.touched}
      onKeyDown={(e) => {
        if (onEnter && e.key === 'Enter') {
          onEnter(e);
        }
      }}
    />
  )
}

export default InputField
