import axios from 'axios';
import toast from './notistack';
import { handleResponseErr } from './apiHelperUtils';

const apiRoot = process.env.REACT_APP_BASE_URL;

const instance = axios.create({
  baseURL: `${apiRoot}/v1/system_admin`,
});

const headers = {
  'Content-Type': 'application/json',
  'Authorization': localStorage.getItem('Authorization'),
  Accept: 'application/json',
  locale: 'en',
}

export function handleAPI(url, params, method = 'GET', data = null, showSuccessMsg) {

  const dataInstance =  instance({
    method,
    headers,
    url,
    params,
    data,
  });

  dataInstance.then(({ data }) => {
    if (showSuccessMsg) {
      toast.success(data.message)
    }
  })

  dataInstance.catch((err) => {
    toast.error(handleResponseErr(err))
  })

  return dataInstance;
}

export default instance