
import axios from 'axios';

export function getLocales(lang) {
  const en = axios.get('https://t99oynj1q2.execute-api.us-east-1.amazonaws.com/dev/api/locales/en');
  const ar = axios.get('https://t99oynj1q2.execute-api.us-east-1.amazonaws.com/dev/api/locales/ar');
  const promise = Promise.all([ar, en])
  return {
    type: 'LOCALES',
    payload: promise,
  }
}

export function updateLocales(values) {
  const promise = axios.post('https://t99oynj1q2.execute-api.us-east-1.amazonaws.com/dev/api/locales', values)
  return {
    type: 'UPDATE_LOCALES',
    payload: promise,
  }
}


export function localesReducer(
  state = {
    keys: [],
    ar: {},
    en: {},
  },
  action,
) {
  switch (action.type) {
    case 'LOCALES_PENDING':
      return {
        ...state,
        fetching: true,
      }

    case 'LOCALES_FULFILLED':
      return {
        ...state,
        keys: Object.keys(action.payload[0].data),
        ar: action.payload[0].data,
        en: action.payload[1].data,
        // ...action.payload.data.data,
        fetching: false,
      }

    default:
      return state;
  }
}
