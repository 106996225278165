import React from 'react'

const Space = ({
  size,
}) => {
  return (
    <div style={{ height: size }} />
  )
}

export default Space
